<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        class="dropdown-notification mr-25"
        menu-class="dropdown-menu-media"
        right
      >
        <template #button-content>
          <feather-icon
            :badge="handlerBellNotifCount"
            badge-classes="bg-danger"
            class="text-body"
            icon="BellIcon"
            size="21"
          />
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
          <div class="dropdown-header d-flex">
            <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
            <b-badge pill variant="light-primary"
              >{{ notification.total }}
            </b-badge>
          </div>
        </li>

        <!-- Notifications -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scrollable-container media-list scroll-area"
          tagname="li"
        >
          <!-- Account Notification -->
          <b-link
            v-for="notifikasi in handlerListNotification"
            :key="notifikasi.admnotif_link"
            @click="$router.push(notifikasi.admnotif_link)"
          >
            <b-media>
              <template #aside>
                <b-avatar size="32" :src="logoUic" />
              </template>
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ notifikasi.admnotif_msg }}
                </span>
              </p>
              <small class="notification-text">{{
                "Status Read :" + notifikasi.admnotif_read === true
                  ? "sudah dibaca"
                  : "belum dibaca" +
                    "|" +
                    notifikasi.admnotif_table_name +
                    "|" +
                    notifikasi.created_at
              }}</small>
            </b-media>
          </b-link>
        </vue-perfect-scrollbar>

        <!-- Cart Footer -->
        <li class="dropdown-menu-footer">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="$router.push('/notifikasi_admin')"
            >Read all notifications</b-button
          >
        </li>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">IDI Cabang Jakpus</p>
            <span class="user-status">Admin</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="logoUic"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
  BFormCheckbox,
  BBadge,
  BMedia,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import logoUic from "@/assets/images/logo/logo-new-idi.png";
import storage from "@/helpers/storage";
import API from "@/api";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BFormCheckbox,
    BBadge,
    BMedia,
    DarkToggler,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      logoUic,
      notification: {
        isLoading: false,
        data: [],
        total: 0,
      },
    };
  },

  computed: {
    handlerBellNotifCount() {
      return this.notification.total.toString(); //convert int to string
    },
    handlerListNotification() {
      return this.notification.data;
    },
  },

  setup() {
    /* eslint-disable global-require */

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    async logout() {
      storage.clearStorage();
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: "Logout berhasil",
          icon: "CheckIcon",
          variant: "success",
        },
      });
      this.$router.push({ path: "/login", replace: true });
    },

    async profile() {},

    async bellNotif() {
      this.notification.isLoading = true;
      API.notifikasi_admin
        .bellNotification()
        .then(({ data }) => {
          this.notification.data = data.data;
          this.notification.total = data.total;
          console.log({ dataNotifs: this.handlerListNotification });
        })
        .catch((error) => {
          this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
        })
        .finally(() => {
          this.notification.isLoading = false;
        });
    },
  },

  mounted() {
    this.bellNotif();
  },
};
</script>
