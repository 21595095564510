export default [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
    resource: "Home",
    action: "read",
  },
  {
    title: "Notifikasi Admin",
    route: "notifikasi_admin",
    icon: "BellIcon",
    resource: "Notifikasi_admin",
    action: "read",
  },
  {
    title: "Forum",
    route: "studi_kasus",
    icon: "BookIcon",
    resource: "StudiKasus",
    action: "read",
  },
  {
    title: "Jadwal KRIP",
    route: "jadwal_krip",
    icon: "CalendarIcon",
    resource: "Jadwal_krip",
    action: "read",
  },
  {
    title: "KRIP",
    route: "krip",
    icon: "CheckCircleIcon",
    resource: "Krip",
    action: "read",
  },
  {
    title: "Rekomendasi Izin Praktik",
    route: "rekomendasi_izin_praktik",
    icon: "BookmarkIcon",
    resource: "Rekomendasi_izin_praktik",
    action: "read",
  },
  {
    title: "STR",
    route: "str",
    icon: "PackageIcon",
    resource: "Str",
    action: "read",
  },
  {
    title: "Mutasi Masuk",
    route: "mutasi_masuk",
    icon: "ArrowRightCircleIcon",
    resource: "Mutasi_masuk",
    action: "read",
  },
  {
    title: "Mutasi Keluar",
    route: "mutasi_keluar",
    icon: "ArrowLeftCircleIcon",
    resource: "Mutasi_keluar",
    action: "read",
  },

  {
    title: "Anggota",
    route: "anggota",
    icon: "UserIcon",
    resource: "Anggota",
    action: "read",
  },
  {
    title: "Admin Management",
    route: "admin-management",
    icon: "UsersIcon",
    resource: "AdminManagement",
    action: "read",
  },
  // menu calon anggota hide
  // {
  //   title: "Calon Anggota",
  //   route: "calon_anggota",
  //   icon: "UserPlusIcon",
  //   resource: "Calon_anggota",
  //   action: "read",
  // },
  {
    title: "Kompentensi",
    route: "kompentensi",
    icon: "Edit2Icon",
    resource: "Kompentensi",
    action: "read",
  },
  {
    title: "Pendidikan",
    route: "pendidikan",
    icon: "AwardIcon",
    resource: "Pendidikan",
    action: "read",
  },
  {
    title: "Galeri",
    route: "galeri",
    icon: "ImageIcon",
    resource: "Galeri",
    action: "read",
  },
  {
    title: "Contact",
    route: "contact",
    icon: "PhoneIcon",
    resource: "Contact",
    action: "read",
  },
  {
    title: "Kegiatan",
    route: "kegiatan",
    icon: "ActivityIcon",
    resource: "Kegiatan",
    action: "read",
  },
  {
    title: "Headline",
    route: "headline",
    icon: "CastIcon",
    resource: "Headline",
    action: "read",
  },
  {
    title: "Halaman",
    route: "halaman",
    icon: "LayoutIcon",
    resource: "Halaman",
    action: "read",
  },
  {
    title: "Berkas",
    route: "berkas",
    icon: "FolderIcon",
    resource: "Berkas",
    action: "read",
  },
  {
    title: "FAQ",
    route: "faq",
    icon: "HelpCircleIcon",
    resource: "Faq",
    action: "read",
  },
  {
    title: "SKP",
    route: "skp",
    icon: "FileTextIcon",
    resource: "Skp",
    action: "read",
  },
  {
    title: "Invoice",
    route: "invoice",
    icon: "DollarSignIcon",
    resource: "Invoice",
    action: "read",
  },
  {
    title: "Web",
    route: "web",
    icon: "GlobeIcon",
    resource: "Web",
    action: "read",
  },
];
